<script lang="ts" setup>
import { ref } from "vue";
import UsersGrid from "@/components/iam/UsersGrid.vue";

const addLink = ref({ name: "IAM-UserAdd", params: { userId: "-", addModus: true } });
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <users-grid :addLink="addLink"></users-grid>
  </v-container>
</template>
